<template>
    <div class="client-layout">
        <Navbar  @toggleMenu="isOpen = !isOpen" :menu="isOpen" />
        <div class="client-container" :class="{'swipe-right-on-menu-open' : isOpen}">
            <div class="client-layout__content">
                 <Sidebar v-model:modelValue="isOpen" :key="locale" @click="sidebarClickHandler" />
                <div class="w-100">
                    <Loader v-if="loading" />
                    <main  v-else>
                        <router-view :key="$route.fullPath" />
                    </main>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import Sidebar from "@/components/app/Sidebar";
import IdleTimer from "@/services/IdleTimer";

import moment from "moment";
import _ from "lodash";

const TIMEOUT = 1800;

export default {
    data: () => ({
        info: {},
        // isOpen: window.innerWidth > 992 ? true : false,
        isOpen: false,
        loading: true,
        isTimeOut: false,
        idleTimer: null
    }),
    methods: {
        async updateCurrencies() {
            const cur = await this.$store.dispatch("fetchCur");
            console.log("Currencies rates updated!", cur);
        },
        sidebarClickHandler(closeMobileMenu) {
        //   closeMobileMenu ? this.isOpen = false : null;
        },
        setIsTimeout() {
            this.isTimeOut = true;
            console.log('timeout', this.idleTimer.timeout);
        },
        async logout() {
            try {
                await this.$store.dispatch("signOut");
                this.$router.push("/signin");
            } catch (e) {
                console.log(e);
                this.$router.push("/signin");
            }
        }

    },
    components: {
        Navbar,
        Sidebar
    },
    computed: {
        error() {
            return this.$store.getters.error;
        },
        locale() {
            return this.isNotEmpty(this.info) ? this.info.locale : "en-US";
        },
        // isOpen() {
        //   return window.innerWidth > 992 ? true : false
        // }
    },
    watch: {
        error(error) {
            const errors = this.getErrorMessages(error);
            console.log(errors);
            if(errors.length) {
                errors.forEach( err => {
                    if(err.includes('Unauthenticated')) {
                        this.$error(err);
                        this.$router.push('/signin');
                    }
                })
                this.$message(errors[0]);
            }
        },
        isTimeOut(val) {
            if(val) {
                this.logout();
            }
        }
    },

    async mounted() {
        if(!this.$role("client")) {
            this.$router.push({ name: 'home'});
        }
        if (!this.isNotEmpty(this.info)) {
            try {
                await this.$store.dispatch("fetchInfo");
                this.info = this.$store.getters.info;
            } catch (err) {
                this.info = {};
                this.$error("Error getting info");
            }
        }
        let curData = localStorage.getItem("currencies");
        if (typeof curData === "string") {
            curData = JSON.parse(curData);
            if (
                moment(curData.date).format("YYYY-MM-DD") !==
                moment().format("YYYY-MM-DD")
            ) {
                this.updateCurrencies();
            }
        } else {
            this.updateCurrencies();
        }

        this.idleTimer = new IdleTimer({
            timeout: process.env.CLIENT_IDLE_TIMEOUT || TIMEOUT,
            onTimeOut: this.setIsTimeout.bind(this),
            onExpired: this.setIsTimeout.bind(this),
        })

        this.loading = false;
    },

    beforeUnmount() {
        this.idleTimer.cleanUp();
    }

};
</script>

<style lang="scss" scoped>
  @import "../assets/ui/_custom-vars.scss";

  .select-wrapper {
    padding: 0 10px;
    background: $white;
    box-shadow: 0px 4px 14px rgba($black, 0.1);
    border-radius: 8px;

  .select-dropdown {
    border: none !important;
  }
}
</style>
