import axios from 'axios'

export default {
    state: {
        bookings: null,
        booking: null,
        operators: null,
        countries: null,
        calendar_bookings: null,
        booking_filter_params: {}
    },
    getters: {
        bookings: state => state.bookings,
        calendar_bookings: state => state.calendar_bookings,
        booking: state => state.booking,
        booking_filter_params: state => state.booking_filter_params,
        operators: state => state.operators,
        countries: state => state.countries,
        boat_data: state => state.booking?.boat || null,
        checkin: state => state.booking?.checkin || null,
        checkout: state => state.booking?.checkout || null,
        add_data: state => state.booking?.add_data || null,
        base: state => state.booking?.base || null,
        country: state => state.booking?.country || null,
        pax: state => state?.booking?.pax || null,
        deposit: state => state.booking?.add_data?.origdeposit || null,
        commission: state => state.booking?.add_data?.commission || null,
        payments: state => state.booking?.payments || null,
        broker_payments: state => state.booking?.broker_payments || null,
        booking_currency: state => state.booking?.currency || 'EUR',
        booking_type: state => state.booking?.type || null,
        crew_members: state => state.booking?.crew_members || null,
    },
    mutations: {
        SET_BOOKINGS(state, bookings) {
            state.bookings = bookings
        },
        SET_CAL_BOOKINGS(state, bookings) {
            state.calendar_bookings = bookings
        },
        SET_BOOKING(state, booking) {
            if(!booking?.pax && booking.boat?.berths) {
                booking.pax = booking.boat.berths;
            }
            state.booking = booking
        },
        SET_BOOKING_FILTER_PARAMS(state, params) {
            state.booking_filter_params = params;
        },
        SET_PAYMENTS(state, payments) {
            state.booking.payments = payments;
        },
        SET_BROKER_PAYMENTS(state, payments) {
            state.booking.broker_payments = payments;
        },
        SET_BOAT_DATA(state, boat_data) {
            state.booking.boat = boat_data;
        },
        SET_ADD_DATA(state, add_data) {
            state.booking.add_data = add_data;
        },
        SET_CHECKIN(state, checkin) {
            if(checkin.indexOf(" ") > -1) {
                let time = checkin.split(" ")[1].match(/(\d+)(?::(\d\d))?\s*(p?)/i);
                if(time[1] === '0')
                checkin = checkin.split(" ")[0] + ' 00:00:00';
            }
            state.booking.checkin = checkin;
        },
        SET_CHECKIN_TIME(state, checkin_time) {
            state.booking.add_data.checkintime = checkin_time;
        },
        SET_CHECKOUT(state, checkout) {
            if(checkout.indexOf(" ") > -1) {
                let time = checkout.split(" ")[1].match(/(\d+)(?::(\d\d))?\s*(p?)/i);
                if(time[1] === '0')
                checkout = checkout.split(" ")[0] + ' 00:00:00';
            }
            state.booking.checkout = checkout;
        },
        SET_CHECKOUT_TIME(state, checkout_time) {
            state.booking.add_data.checkouttime = checkout_time;
        },
        SET_BASE(state, base) {
            state.booking.base = base;
        },
        SET_BASE_TO(state, base) {
            state.booking.add_data.checkout_base = base;
        },
        SET_PAX(state, pax) {
            state.booking.pax = pax;
        },
        SET_DEPOSIT(state, deposit) {
            state.booking.add_data.origdeposit = deposit;
        },
        SET_COMMISSION(state, commission) {
            state.booking.add_data.commission = commission;
        },
        SET_PRICE(state, price) {
            state.booking.price = price;
        },
        SET_COUNTRY(state, country) {
            state.booking.country = country;
        },
        SET_OPERATORS(state, operators) {
            state.operators = operators;
        },
        SET_COUNTRIES(state, countries) {
            state.countries = countries;
        },
        SET_BOOKING_EXTRAS(state, extras) {
            if(state.booking) {
                state.booking.extras = extras;
            }
        },
        SET_BOOKING_EXTRA(state, extra) {
            if(state.booking) {
                if(extra?.id) {
                    let existing = state.booking.extras.find( it => it.id === extra.id);
                    if(existing) {
                        existing = {...extra}
                    }
                } else {
                    state.booking.extras.push({...extra})
                }
            }
        },
        SET_COMPANY_DETAILS(state, company) {
            if(state.booking) {
                state.booking.company = company;
            }
        },
        SET_CREW_MEMBERS(state, data) {
            if(state.booking) {
                state.booking.crew_members = data;
            }
        },
        SET_RESERVATION_ID(state, val) {
            state.booking.reservation_id = val;
        },
        CHANGE_PAYMENT(state,payment) {
            if(state.booking.payments && state.booking.payments.length) {
                state.booking.payments = state.booking.payments.map( p => {
                    if(p.payment_num === payment.payment_num) {
                        p = {... payment};
                    }
                    return p;
                })
            }
            else {
                state.booking.payments = [];
                state.booking.payments.push(payment);
            }
        },
        ADD_PAYMENT(state,payment) {
            if(state.booking.payments && typeof state.booking.payments === 'object') {
                state.booking.payments.push(payment);
            }
            else {
                state.booking.payments = [];
                state.booking.payments.push(payment);
            }
        },
        REMOVE_PAYMENT(state,payment_num) {
            if(state.booking.payments && typeof state.booking.payments === 'object') {
                let num = 0;
                const payments = state.booking.payments.filter( p => p.payment_num !== payment_num);
                if(payments.length) {
                    payments.map( p => {
                        p.payment_num = ++num;
                        return p;
                    });
                }
                state.booking.payments = payments;
            }

        },
        CHANGE_BOOKING_CURRENCY(state,currency){
            state.booking.currency = currency;
        },
        CHANGE_BROKER_PAYMENT(state,payment) {
            if(state.booking.broker_payments && typeof state.booking.broker_payments === 'object') {
                state.booking.broker_payments = state.booking.broker_payments.map( p => {
                    if(p.payment_num === payment.payment_num) {
                        p = {... payment};
                    }
                    return p;
                })
            }
        },
        ADD_BROKER_PAYMENT(state,payment) {
            if(state.booking.broker_payments && typeof state.booking.broker_payments === 'object') {
                state.booking.broker_payments.push(payment);
            }
            else {
                state.booking.broker_payments = [];
                state.booking.broker_payments.push(payment);
            }
        },
        REMOVE_BROKER_PAYMENT(state,payment_num) {
            if(state.booking.broker_payments && typeof state.booking.broker_payments === 'object') {
                let num = 0;
                const payments = state.booking.broker_payments.filter( p => p.payment_num !== payment_num);
                if(payments.length) {
                    payments.map( p => {
                        p.payment_num = ++num;
                        return p;
                    });
                }
                state.booking.broker_payments = payments;
            }

        },
    },
    actions: {
        fetchOperators({commit,dispatch},search) {
            return new Promise((resolve, reject) => {

                axios.get(process.env.VUE_APP_API_PATH + 'v1/search/?class=company&field=enname,name&search='+search) .then(res => {
                    const operators = [];
                    if(typeof res.data === 'object') {
                        res.data.forEach(it => {
                            const name = it['enname'] ? it['enname'] : it['name'];
                            if(name) {
                                operators.push(name);
                            }
                        })
                    }
                    commit('SET_OPERATORS',operators)
                    resolve();
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            })
        },

        fetchCountries({commit,dispatch},search) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_API_PATH + 'v1/search/?class=order&field=country&search='+search) .then(res => {
                    const countries = [];
                    if(typeof res.data === 'object') {
                        res.data.forEach(it => {
                            if(it['country']) {
                                countries.push(it['country']);
                            }
                        })
                    }
                    commit('SET_COUNTRIES',countries)
                    resolve();
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            })
        },

        fetchCalendarBookings({commit,dispatch},queryData) {
            return new Promise((resolve, reject) => {
                queryData.calendar = 1;
                axios.get(process.env.VUE_APP_API_PATH + 'v1/get-bookings',{params:queryData}) .then(res => {
                    console.log(res)
                    commit('SET_CAL_BOOKINGS',res.data)
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                });


            })
        },
        fetchOrders({commit,dispatch},queryData) {
            return new Promise((resolve, reject) => {

                axios.get(process.env.VUE_APP_API_PATH + 'v1/get-bookings',{params:queryData}) .then(res => {
                    commit('SET_BOOKINGS',res.data)
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                });


            })
        },
        fetchBooking({commit,dispatch},id) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_API_PATH + 'v1/bookings/'+id) .then(res => {
                    commit('SET_BOOKING',res.data)
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            })
        },
        updateBooking({commit},data) {
            return new Promise((resolve, reject) => {
                axios.put(process.env.VUE_APP_API_PATH + 'v1/bookings/'+data['id'],data) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            })
        },
        storeBooking({commit},data) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_API_PATH + 'v1/bookings',data) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            })
        },
        deleteBooking({commit},id) {
            return new Promise((resolve, reject) => {
                if(!id) {
                    reject("no id provided");
                }
                axios.delete(process.env.VUE_APP_API_PATH + 'v1/bookings/'+id) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            })
        },
        copyBooking({commit},id) {
            return new Promise((resolve, reject) => {
                if(!id) {
                    reject("no id provided");
                }
                axios.get(process.env.VUE_APP_API_PATH + 'v1/bookings/copy/'+id) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            })
        },
        storeExtra({commit},data) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_API_PATH + 'v1/extras',data) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            })
        },
        updateExtra({commit},data) {
            return new Promise((resolve, reject) => {
                axios.put(process.env.VUE_APP_API_PATH + 'v1/extras/'+data['id'],data) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            })
        },
        deleteExtra({commit},id) {
            return new Promise((resolve, reject) => {
                axios.delete(process.env.VUE_APP_API_PATH + 'v1/extras/'+id) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            })
        },
        storeBookingDocuments({commit,dispatch},{order_id,form, files}) {
            return new Promise((resolve, reject) => {
                if(!form || !Object.keys(form).length) {
                    commit('SET_ERROR','No form data to send');
                    reject(err);
                }

                const formdata = new FormData();
                formdata.append("data",JSON.stringify(form));
                if(files && files.length) {
                    for( let i = 0; i < files.length; i++ ){
                        let file = files[i].file;
                        let type = files[i].type;
                        if(type === 'Avatar') {
                            formdata.append(`${type}`,file,file.name);
                        } else {
                            formdata.append(`${type}[]`,file,file.name);
                        }
                    }
                }
                const url = process.env.VUE_APP_API_PATH + 'v1/bookings/' + order_id + '/save-documents';
                axios.post(url, formdata,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(res => {
                    if(!res.data || typeof res.data === 'undefined') {
                        console.log(res);
                        commit('SET_ERROR','error when trying to save document');
                        reject(res.data);
                    }
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            });
        },
        storeDocuments({commit,dispatch},{modelId,model,form, files}) {
            return new Promise((resolve, reject) => {
                if(!form || !Object.keys(form).length) {
                    commit('SET_ERROR','No form data to send');
                    reject(err);
                }

                const formdata = new FormData();
                formdata.append("model",model);
                formdata.append("data",JSON.stringify(form));
                if(files && files.length) {
                    for( let i = 0; i < files.length; i++ ){
                        let file = files[i].file;
                        let type = files[i].type;
                        if(type === 'Avatar') {
                            formdata.append(`${type}`,file,file.name);
                        } else {
                            formdata.append(`${type}[]`,file,file.name);
                        }
                    }
                }
                const url = process.env.VUE_APP_API_PATH + 'v1/common/save-documents/' + modelId;
                axios.post(url, formdata,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(res => {
                    if(!res.data || typeof res.data === 'undefined') {
                        console.log(res);
                        commit('SET_ERROR','error when trying to save document');
                        reject(res.data);
                    }
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err);
                });
            });
        },

  }
}
