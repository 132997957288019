import Pusher from "pusher-js";
import Echo from "laravel-echo";

export function useEcho(token) {
    if(!window.Echo) {
        const logToConsole = !!(+process.env.PUSHER_LOG);
        Pusher.logToConsole = logToConsole;
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: '05b9ecbae32408b1639f',
            cluster: 'eu',
            encrypted: true,
            logToConsole: logToConsole,
            authEndpoint: process.env.VUE_APP_BASE_URL + '/broadcasting/auth',
            auth: {
                headers: {
                    Authorization: "Bearer " + token,
                },
            },
        });
    } else {
        if(window.Echo?.connector?.options?.auth) {
            window.Echo.connector.options.auth.headers['Authorization'] = 'Bearer ' + token;
        }
        window.Echo.options.auth.headers['Authorization'] = 'Bearer ' + token;
    }
}
